export const base64ToBlob = (base64String: string, contentType: string = 'image/png') => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
}

export const blobToBase64 = (blob: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            //@ts-ignore
            const base64String = reader.result?.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}
export const capitalizeAndRemoveUnderlines = (value: string, removeUnderlines: boolean = false) => {
    let capitalized = value.charAt(0).toUpperCase() + value.slice(1);
    return removeUnderlines ? capitalized.replace(/_/g, ' ') : capitalized;
}



export {reduceImageFileSize} from './image'