import { Typography } from 'antd';
import React from 'react';
import { useMemo } from 'react';
import { Menu } from 'antd';
import { Layout } from 'antd';
import { Paths } from 'constants/Paths';
import { UploadOutlined } from '@ant-design/icons';
import { SolutionOutlined } from '@ant-design/icons';
import { ProfileOutlined } from '@ant-design/icons';
import { useSidebar } from './useSidebar';
import './styles.scss'

const { Sider } = Layout;

export const Sidebar = React.memo(() => {

  const items = useMemo(() => [
    { key: '1', path: Paths.home, icon: <UploadOutlined />, label: 'Upload Image' },
    { key: '2', path: Paths.list, icon: <ProfileOutlined />, label: 'Accidents List' },
    { key: '3', path: Paths.details, icon: <SolutionOutlined />, label: 'Damage Details' },
  ], []);

  const { selectedKey, onClickMenu } = useSidebar(items);

  return (
    <Sider className={'app-sidebar'}>
      <div>
        <Typography className={'logo-part'}>DAMAGE INSPECTION</Typography>
        <hr/>
      </div>
      <Menu
        selectedKeys={[selectedKey]}
        onClick={onClickMenu}
        className="sider-menu"
        mode="inline"
        defaultSelectedKeys={['1']}
        items={items}
      >
      </Menu>
    </Sider>
  );
})