import React from 'react';
import {Col} from 'antd';
import {Row} from 'antd';
import {Image} from 'antd';
import {useAppSelector} from 'store/hooks';
import {CarParts} from 'components/carParts';
import {DamagedParts} from "components/damagedParts";
import {selectCurrentPhoto} from 'components/carousel/carouselSlice';
import 'components/carousel/carouselMainContainer/style.scss';

export const CarouselMainContainer = () => {
    const currentPhoto = useAppSelector(selectCurrentPhoto);
    return (
        <Row justify={'space-between'} className={'carousel-row'}>
            <Col span={6} className={'sider'}>
                <CarParts/>
            </Col>
            <Col span={11} className={'middle-col'}>
                <Image src={currentPhoto} preview={false} width={'100%'}/>
            </Col>
            <Col span={6} className={'sider'}>
                <DamagedParts/>
            </Col>
        </Row>
    )

}