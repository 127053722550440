import {blobToBase64, reduceImageFileSize} from "helpers";
import {RootState} from 'store/store';
import {createSlice} from '@reduxjs/toolkit';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {fetchLastPhoto} from "components/carousel/carouselSlice";
import { imageSettings } from "constants/image";
interface IPartsData {
    [key: string]: string | object
}
interface IImageAllInfo {
    [key: string]: string | object | any
}
export interface IImageSlice {
    status: string,
    displayImage: string,
    imageAllInfo: IImageAllInfo,
    requestBody: object,
    classInfo: any,
    loading: boolean,
    partsRequestData : Array<object>,
    currentImageId : string
}

const initialState: IImageSlice = {
    status: '',
    displayImage: '',
    imageAllInfo: {},
    requestBody: {},
    classInfo: {
        status: ''
    },
    loading: false,
    partsRequestData : [],
    currentImageId : ""
}
const url = "https://api.carinspect.io/";
type IFetchParameter = File;
const requestHeaders: HeadersInit = new Headers();
// @ts-ignore
requestHeaders.set('ngrok-skip-browser-warning', true);

const backRequestHeadersForParts: HeadersInit = new Headers();
backRequestHeadersForParts.set('X-Parse-Application-Id', '9iiou1tzdbNn4UqqQliMBviUG7hFTNC5ZcrZlW1G');
backRequestHeadersForParts.set('X-Parse-REST-API-Key', "Lo16duF71iVWiCDY3wSuKujdUamQybsG83vyY9wk");
backRequestHeadersForParts.set('Content-Type', "application/json");

const backRequestHeaders: HeadersInit = new Headers();
backRequestHeaders.set('X-Parse-Application-Id', 'vtyI3Rb8wxYa4AY5WbCuou3A1fvrPMJ85nMzYci0');
backRequestHeaders.set('X-Parse-REST-API-Key', "NCx0eZccWog9ead4BjbK3CNK5BFmJXIDNDGRKxBt");
backRequestHeaders.set('Content-Type', "application/json");

const urlForParts = "https://codulate.b4a.io/classes/parts";
const backEndUrl = "https://parseapi.back4app.com/classes/Annotations";

export const savePhotoList = createAsyncThunk(
    'image/savePhotoList',
    async (data: any, {rejectWithValue}) => {
        try {
            const response = await fetch(backEndUrl, {
                method: 'POST',
                headers: backRequestHeaders,
                body: JSON.stringify(data)
            });
            return await response.json();
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);
export const fetchParts = createAsyncThunk(
    'image/fetchParts',
    async (data:any,{rejectWithValue}) => {
        try {
            const params = new URLSearchParams({
                where: JSON.stringify({
                    $or: data
                })
            });
            const response = await fetch(`${urlForParts}?${params}`,{
                headers: backRequestHeadersForParts
            });
            return  await response.json();
        } catch (error:any) {
            return rejectWithValue(error.message);
        }
    }
);
export const classInfoImage = createAsyncThunk(
    'image/classInfoImage',
    async (imageId:any, {rejectWithValue}) => {
        try {
            const response = await fetch(`${url}classesInfo/${imageId}`, {
                method: "GET",
                headers: requestHeaders
            });
            return  await response.json();
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
)
export const annotateImage = createAsyncThunk(
    'image/annotateImage',
    async (imageId:any, {rejectWithValue}) => {
        try {
            const response = await fetch(`${url}annotation_image/${imageId}`, {
                method: "GET",
                headers: requestHeaders
            });
            const blob = await response.blob();
            const base64String = await blobToBase64(blob);
            const src = URL.createObjectURL(blob);
            return {
                src,
                base64String,
            };
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
)
export const classifyImage = createAsyncThunk(
    'image/classifyImage',
    async (imageId:any, {rejectWithValue}) => {
        try {
            return await fetch(`${url}classify/${imageId}`, {
                method: "GET",
                headers: requestHeaders
            });
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
)
export const fetchImage = createAsyncThunk(
    'image/fetchImage',
    async (image: IFetchParameter, {rejectWithValue}) => {
        try {
            const data = new FormData();
            const imageId = Math.random().toString(36).substring(2, 7) + Date.now();
            const splitName = image.name.split(".") || [];
            const imageExtension = splitName.length ? splitName[splitName.length - 1] : '';
            const fileName = imageId + "." + imageExtension;
            data.append("image", image, fileName );
            data.append("image_id", imageId);
            const response = await fetch(`${url}upload`, {
                method: 'POST',
                headers: requestHeaders,
                body: data
            });
            return {...response,imageId};
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);


export const imageSlice = createSlice({
    extraReducers: (builder) => {
        builder
            .addCase(fetchImage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchImage.fulfilled, (state, action) => {
                state.currentImageId = action.payload.imageId;
                state.status = 'fulfilled';
            })
            .addCase(fetchImage.rejected, (state) => {
                state.status = 'failed';
                state.loading = false;
            })
            .addCase(annotateImage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(annotateImage.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.displayImage = action.payload.src;
                state.loading = false;
                state.imageAllInfo = {...state.imageAllInfo, src: action.payload.src};
                state.requestBody = {...state.requestBody, AnnotationId: action.payload.base64String};
            })
            .addCase(annotateImage.rejected, (state) => {
                state.status = 'failed';
                state.loading = false;
            })
            .addCase(classInfoImage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(classInfoImage.fulfilled, (state, action) => {
                state.classInfo.status = 'fulfilled';
                state.imageAllInfo = {...state.imageAllInfo, ...action.payload}
                state.partsRequestData = action.payload.instances.map((_instance: any) => {
                    return {part_name: _instance.className}
                })
            })
            .addCase(classInfoImage.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(fetchParts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchParts.fulfilled, (state, action) => {
                const partsObj:IPartsData  = {};
                action.payload.results?.forEach((_data: any) => {
                    partsObj[_data.part_name] = {..._data, AnnotationId: null};
                });
                const annotationData : any = {};
                annotationData.instances  = state.imageAllInfo?.instances.map((_instance : any) =>  {
                    return {..._instance,partInfo: partsObj[_instance.className] || {}}
                });
                state.requestBody = {...state.requestBody, AnnotationData: JSON.stringify(annotationData)};
                state.status = 'fulfilled';
            })
            .addCase(fetchParts.rejected, (state) => {
                state.status = 'failed';
            })
    },
    initialState,
    name: 'image',
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    },
});
export const selectDisplayedImage = (state: RootState) => state.image.displayImage;
export const selectLoadingState = (state: RootState) => state.image.loading;

export const {setLoading} = imageSlice.actions;

export const uploadAndFetchImage = (img: any) => async (dispatch: any, getState: any) => {
    try {
        dispatch(setLoading(true))
        const reducedImage = await reduceImageFileSize(img, imageSettings);
        const fetchImageAction = dispatch(fetchImage(reducedImage));

        fetchImageAction.then((action: any) => {
            if (action.type === fetchImage.fulfilled.type) {
                return dispatch(classifyImage(getState().image.currentImageId));
            }
        })
            .then((action: any) => {
                if (action && action.type === classifyImage.fulfilled.type) {
                    return dispatch(annotateImage(getState().image.currentImageId));
                }
            })
            .then((action: any) => {
                if (action && action.type === annotateImage.fulfilled.type) {
                    return dispatch(classInfoImage(getState().image.currentImageId));
                }
            })
            .then((action: any) => {
                if (action && action.type === classInfoImage.fulfilled.type) {
                    return dispatch(fetchParts(getState().image.partsRequestData));
                }
            })
            .then((action: any) => {
                if (action && action.type === fetchParts.fulfilled.type) {
                    return dispatch(savePhotoList(getState().image.requestBody));
                }
            })
            .then((action: any) => {
                if (action && action.type === savePhotoList.fulfilled.type) {
                    return dispatch(fetchLastPhoto());
                }
            });
    } catch (error) {
        console.error('Error:', error);
    }
}
