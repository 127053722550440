import App from 'App'
import React from 'react';
import {store} from 'store/store'
import {Provider} from 'react-redux'
import {createRoot} from 'react-dom/client'
import 'index.css'

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container!)

root.render(
    <React.Suspense>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.Suspense>
)
