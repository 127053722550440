import {base64ToBlob} from "helpers";
import {RootState} from 'store/store';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

// @ts-ignore
import Parse from "parse/dist/parse.min.js";

// Your Parse initialization configuration goes here
const PARSE_APPLICATION_ID = 'vtyI3Rb8wxYa4AY5WbCuou3A1fvrPMJ85nMzYci0';
const PARSE_HOST_URL = "https://parseapi.back4app.com/";
const PARSE_JAVASCRIPT_KEY = 'UVubdc6lGxjGiP2PF85xc6n8ZP6juDGismrMyTKM';
Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
Parse.serverURL = PARSE_HOST_URL;

const requestHeaders: HeadersInit = new Headers();
requestHeaders.set('X-Parse-Application-Id', 'vtyI3Rb8wxYa4AY5WbCuou3A1fvrPMJ85nMzYci0');
requestHeaders.set('X-Parse-REST-API-Key', "NCx0eZccWog9ead4BjbK3CNK5BFmJXIDNDGRKxBt");
requestHeaders.set('Content-Type', "application/json");

type IBtn = {
    next: boolean,
    previous: boolean
}
const Annotation = Parse.Object.extend("Annotations");

export interface ICarouselSlice {
    photoList: Array<any>;
    currentPhoto: string;
    filteredPhotoList: any[];
    buttons: IBtn;
    status: string,
    currentCarInfo: object,
    loading: boolean,
    prevNextLoading: boolean,
    skipCount: number
    storedPhotoList : any[]
}

export const initialState: ICarouselSlice = {
    photoList: [],
    currentPhoto: '',
    filteredPhotoList: [],
    buttons: {
        next: false,
        previous: true
    },
    status: '',
    currentCarInfo: {},
    loading: false,
    prevNextLoading: false,
    skipCount : 0,
    storedPhotoList : []
};
export const fetchLastPhoto = createAsyncThunk(
    'carousel/fetchLastPhoto',
    async () => {
        const query = new Parse.Query(Annotation);
        query.descending('createdAt');
        try {
            const response = await query.first();
            return response
        } catch (err) {
            console.log(err);
        }
    }
)
export const fetchPhotoList = createAsyncThunk(
    'carousel/fetchPhotoList',
    async (_, {getState}) => {
        const query = new Parse.Query(Annotation);
        query.descending('createdAt');
        // @ts-ignore
        query.skip(getState().carousel.skipCount)
        query.limit(10);
        try {
            const response = await query.find();
            return response
        } catch (err) {
            console.log(err);
        }
    }
);
export const carouselSlice = createSlice({
    name: 'carousel',
    initialState,
    reducers: {
        setCurrentPhoto: (state, action) => {
            state.currentPhoto = action.payload;
        },
        setCurrentCarInfo: (state, action) => {
            state.currentCarInfo = {...action.payload, AnnotationData: JSON.parse(action.payload.attributes.AnnotationData)};
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        photoListFilter: (state, action) => {
            if (action.payload === 'next') {
                let indexOfLastElem = state.photoList.findIndex(elem => elem.id === state.filteredPhotoList[4].id) + 1;
                state.filteredPhotoList = [...state.filteredPhotoList.slice(1), state.photoList[indexOfLastElem]];
                state.buttons.previous = false;
                state.buttons.next = ++indexOfLastElem === state.photoList.length;

            } else if (action.payload === 'previous') {
                let indexOfFirstElem = state.photoList.findIndex(elem => elem.id === state.filteredPhotoList[0].id) - 1;
                state.filteredPhotoList = [state.photoList[indexOfFirstElem], ...state.filteredPhotoList.slice(0, 4)];
                state.buttons.next = false;
                state.buttons.previous = indexOfFirstElem === 0;
            }
            state.currentCarInfo = {
                ...state.filteredPhotoList[state.filteredPhotoList.length - 1],
                AnnotationData: JSON.parse(state.filteredPhotoList[state.filteredPhotoList.length - 1].attributes.AnnotationData)
            }
            state.currentPhoto = URL.createObjectURL(base64ToBlob(state.filteredPhotoList[state.filteredPhotoList.length - 1].attributes.AnnotationId));
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPhotoList.pending, (state) => {
                if (!state.photoList.length) {
                    state.loading = true;
                }
                state.prevNextLoading = true;
            })
            .addCase(fetchPhotoList.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.loading = false;
                state.prevNextLoading = false;
                state.photoList = state.photoList.concat(action.payload) || [];
                if (state.photoList.length > 5 && !state.skipCount) {
                    state.buttons.previous = true;
                    state.buttons.next = false;
                    state.filteredPhotoList = state.photoList.slice(0, 5);
                } else if(!state.skipCount){
                    state.filteredPhotoList = state.photoList;
                    state.buttons.previous = true;
                    state.buttons.next = true;
                }
                if (state.photoList.length && !state.skipCount) {
                    state.currentPhoto = URL.createObjectURL(base64ToBlob(state.filteredPhotoList[0].attributes.AnnotationId));
                    state.currentCarInfo = {
                        ...state.filteredPhotoList[0],
                        AnnotationData: JSON.parse(state.filteredPhotoList[0].attributes.AnnotationData)
                    };
                }
                state.skipCount += action.payload.length;
                if (action.payload.length) {
                    state.buttons.next = false;
                }
            })
            .addCase(fetchPhotoList.rejected, (state) => {
                state.loading = false;
                state.prevNextLoading = false;
            })
            .addCase(fetchLastPhoto.pending, () => {

            })
            .addCase(fetchLastPhoto.fulfilled, (state, action) => {
                state.photoList = [action.payload, ...state.photoList];
                state.filteredPhotoList = state.photoList.slice(0,5);
                state.currentPhoto = URL.createObjectURL(base64ToBlob(action.payload.attributes.AnnotationId));
                state.currentCarInfo = {
                    ...action.payload,
                    AnnotationData: JSON.parse(action.payload.attributes.AnnotationData)
                };
                state.buttons.next = false;
                state.buttons.previous = true;
                state.skipCount++;
            })
            .addCase(fetchLastPhoto.rejected, () => {

            })
    }
});
export const selectCurrentPhoto = (state: RootState) => state.carousel.currentPhoto;
export const selectFilteredPhotoList = (state: RootState) => state.carousel.filteredPhotoList;
export const selectCarInfo = (state: RootState) => state.carousel.currentCarInfo;
export const selectPhotoListLoading = (state: RootState) => state.carousel.loading;


export const {setCurrentPhoto, setCurrentCarInfo, photoListFilter} = carouselSlice.actions;
