import React from 'react';
import {Spin} from "antd";
import { PageTitles } from '../../constants';
import { PageTitle } from '../pageTitle';
import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {fetchPhotoList} from 'components/carousel/carouselSlice';
import {CarouselItemBar} from 'components/carousel/carouselItemBar';
import {selectPhotoListLoading} from 'components/carousel/carouselSlice';
import {selectFilteredPhotoList} from 'components/carousel/carouselSlice';
import {CarouselMainContainer} from 'components/carousel/carouselMainContainer';

export const Carousel = () => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectPhotoListLoading);
    const photoList = useAppSelector(selectFilteredPhotoList);

    useEffect(() => {
        if (!photoList.length) {
            dispatch(fetchPhotoList());
        }
    }, [dispatch, photoList]);

    return (
        <Spin spinning={loading} tip='Loading' size="large" style={{marginTop: '30px'}}>
            <PageTitle title={PageTitles.damage.details}/>
            {!loading && (!photoList.length ? <div>Data not found</div> :
                <>
                    <CarouselItemBar items={photoList}/>
                    <CarouselMainContainer/>
                </>)
            }
        </Spin>
    )
}
