import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { To } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

interface ISidebarItem {
  key: string,
  path?: string,
  icon?: JSX.Element
}

export const useSidebar = (items: ISidebarItem[]) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedKey, setSelectedKey] = useState(items.find(_item => location.pathname === _item.path)?.key || '');

  const onClickMenu = useCallback(async (item: ISidebarItem) => {
    const clicked = items.find(_item => _item.key === item.key);
    navigate(clicked?.path as To);
  }, [items, navigate]);

  useEffect(() => {
    const findSelectedKey = items.find(_item => location.pathname === _item.path)?.key || '';
    setSelectedKey(findSelectedKey);
  }, [location, items]);

  return { selectedKey, onClickMenu }
}