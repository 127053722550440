import { Card,Col, Row } from 'antd';
import { useAppSelector } from 'store/hooks';
import { capitalizeAndRemoveUnderlines } from 'helpers';
import { selectCarInfo } from 'components/carousel/carouselSlice';
import './style.scss'

export const CarParts = () => {
  const { AnnotationData }: any = useAppSelector(selectCarInfo);
  return (
    <Card className={'car-parts-container'} title="Car Parts" bordered={false}>
      <div className={'car-parts'}>
        {AnnotationData.instances.map((_part: any) => {
          return <Row key={_part.classId * Math.random() * 100} className={'part'}>
            <Col span={1} className={'left'} style={{ backgroundColor: `${_part.parts[0].color}` }}></Col>
            <Col span={23} className={'right'}>{capitalizeAndRemoveUnderlines(_part.className, true)}</Col>
          </Row>
        })}
      </div>
    </Card>
  )
}