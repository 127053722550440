export type IImageSettings = {
    maxWidth: number,
    maxHeight: number,
    quality: number
}

export const imageSettings: IImageSettings = {
    maxWidth: 600,
    maxHeight: 400,
    quality: 1
}