import { IImageSettings } from 'constants/image'

function dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
}

// Function to reduce image file size and return the result as a new file
export function reduceImageFileSize(inputFile: File, {maxWidth, maxHeight, quality }: IImageSettings): Promise<File> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(inputFile);
    
        reader.onload = function () {
            const img = document.createElement('img');
            img.src = URL.createObjectURL(inputFile);

            img.onload = function () {
                let width = img.width;
                let height = img.height;

                const aspectRatio = width / height;
                if (width > maxWidth || height > maxHeight) {
                    if (width / maxWidth > height / maxHeight) {
                        width = maxWidth;
                        height = Math.round(width / aspectRatio);
                    } else {
                        height = maxHeight;
                        width = Math.round(height * aspectRatio);
                    }
                }
    
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = width;
                canvas.height = height;

                if(ctx) {
                    ctx.imageSmoothingQuality = 'high';
                    ctx.drawImage(img, 0, 0, width, height);

                    const compressedImage = canvas.toDataURL('image/jpeg', quality);
    
                    // Convert the data URL to a Blob
                    const blob = dataURItoBlob(compressedImage);
    
                    // Create a new File object with the Blob and original file name
                    const reducedFile = new File([blob], inputFile.name, { type: 'image/jpeg' });
    
                    console.log('Original File Size:', inputFile.size / 1024, 'KB');
                    console.log('Reduced File Size:', reducedFile.size / 1024, 'KB');
                    
                    resolve(reducedFile);
                }
            };
        };
    })
}
