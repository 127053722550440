import { Layout } from 'antd';
import { Space } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';
import './styles.scss'
import { AppFooter } from './Footer';
import { Sidebar } from './sidebar';

const { Content } = Layout;

export const AppLayout = React.memo(() => {
  return (
    <div className={'damage-inspection'}>
      <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
        <Layout>
          <Sidebar/>
          <Layout>
            <Content>
              <div className={'main-content'}>
                <Outlet />
              </div>
            </Content>
            <AppFooter/>
          </Layout>
        </Layout>
      </Space>
    </div>
  );
})