import {useSelector} from 'react-redux';
import {TypedUseSelectorHook} from 'react-redux';
import {imageSlice} from 'components/uploadImage/imageSlice';
import {carouselSlice} from 'components/carousel/carouselSlice';
import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';

export const store = configureStore({
    reducer: {
        carousel: carouselSlice.reducer,
        image: imageSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
