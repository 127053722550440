import { Row, Col } from 'antd';
import { useAppSelector } from 'store/hooks';
import { Button, Card, Typography } from 'antd';
import { capitalizeAndRemoveUnderlines } from 'helpers';
import { selectCarInfo } from 'components/carousel/carouselSlice';
import 'components/carParts/style.scss'

export const DamagedParts = () => {
  const { AnnotationData }: any = useAppSelector(selectCarInfo);
  return (
    <Card className={'damage-parts-container'} title="Damaged Parts" bordered={false}>
      {!!AnnotationData.instances.length &&
      <>
        <div className={'parts'}>
          <Typography.Title level={4} className={'second-title'}>Actual Damage</Typography.Title>
          {AnnotationData.instances.map((_part: any) => {
            return <Row justify={'space-between'} key={_part.classId * Math.random() * 100} className={'damage-price'}>
              <Col><strong>{capitalizeAndRemoveUnderlines(_part.className, true)}</strong></Col>
              <Col>{_part.partInfo?.cost ? `${_part.partInfo?.cost} AMD` : 'Not found'} </Col>
            </Row>
          })}
          <hr/>
          <Row className={'summary'} justify={'space-between'}>
            <Col><strong>Summary</strong></Col>
            <Col>{AnnotationData.instances.reduce((acc: number, curVal: any) => acc + (curVal.partInfo?.cost || 0), 0)} AMD</Col>
          </Row>
        </div>
        <Button block type="primary" style={{ marginTop: 'auto', marginBottom: '20px' }}>Save</Button>
      </>
      }
    </Card>
  )
}