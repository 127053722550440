import React from 'react';
import { Result, Button } from 'antd';
import {Home} from 'pages/home';
import {Paths} from 'constants/Paths';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { Carousel } from '../components/carousel';
import { DamageTable } from '../components/damageTable';
import { AppLayout } from '../layouts/app';

export const Navigation = () => {
    return (
        <BrowserRouter>
            <Routes>
              <Route path={Paths.home} element={<AppLayout />}>
                <Route path={Paths.home} element={<Home/>}/>
                <Route path={Paths.list} element={<DamageTable/>}/>
                <Route path={Paths.details} element={<Carousel/>}/>
                <Route path={'*'} element={<Result
                  status="403"
                  title="403"
                  subTitle="Sorry, you are not authorized to access this page."
                  extra={<Button onClick={() => history.back()} type="primary">Back</Button>}
                />}/>
              </Route>
            </Routes>
        </BrowserRouter>
    )
}
