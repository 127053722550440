import React, {useCallback} from 'react'
import {Col} from 'antd';
import {Row} from 'antd';
import {Spin} from 'antd';
import {Image} from 'antd';
import {Button} from 'antd';
import {base64ToBlob} from "helpers";
import {useAppDispatch} from 'store/hooks';
import {CaretLeftOutlined} from '@ant-design/icons';
import {CaretRightOutlined} from '@ant-design/icons';
import {fetchPhotoList, photoListFilter} from 'components/carousel/carouselSlice';
import {setCurrentPhoto} from 'components/carousel/carouselSlice';
import { setCurrentCarInfo} from 'components/carousel/carouselSlice';
import 'components/carousel/carouselItemBar/style.scss';
import {useTypedSelector} from "store/store";


export const CarouselItemBar = ({items}: any) => {
    const dispatch = useAppDispatch();
    const {
        carousel: {
            buttons: { previous, next },
            currentCarInfo,
            photoList,
            prevNextLoading
        }
    }: any = useTypedSelector(state => state)

    const onImageClick = useCallback((item: any) => {
        dispatch(setCurrentCarInfo(item))
        dispatch(setCurrentPhoto(item.imgSrc))
    }, []);

    const onArrowClicks = useCallback((type: 'previous' | 'next') => {
        dispatch(photoListFilter(type));
        if (type === "next" && currentCarInfo._objCount % 5 === 0 && currentCarInfo._objCount % 2 !== 0
            && photoList.length - currentCarInfo._objCount <= 10) {
            dispatch(fetchPhotoList());
        }
    }, [dispatch, currentCarInfo, photoList]);

    return (
        <Row align={"middle"} justify={'space-evenly'}
             className={'custom-carousel'}>
            <Col span={1} className={'arrow-btn'}>
                <Button size={'large'} disabled={previous} onClick={() => onArrowClicks('previous')}>
                    <CaretLeftOutlined/>
                </Button>
            </Col>
            {items.map((item: any,) => {
                let imgSrc = '';
                if (item.attributes.AnnotationId) {
                    imgSrc = URL.createObjectURL(base64ToBlob(item.attributes.AnnotationId));
                }
                return <Col key={item.id} span={4}>
                    <Button onClick={() => onImageClick({...item,attributes: { ...item.attributes} ,imgSrc})} className={'carousel-item-btn'}>
                        <Image src={imgSrc} preview={false} height={100} width={'100%'}/>
                    </Button>
                </Col>
            })}
            <Col span={1} className={'arrow-btn'}>
                <Button size={'large'} disabled={next} onClick={() => onArrowClicks('next')}>
                    {prevNextLoading ? <Spin /> : <CaretRightOutlined />}
                </Button>
            </Col>
        </Row>
    )
}