import React from 'react';
import { Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { RightCircleOutlined } from '@ant-design/icons';
import { PageTitles } from '../../constants';
import { PageTitle } from '../pageTitle';
import './styles.scss';

interface DataType {
  key: string;
  caseNumber: string;
  date: string;
  time: string;
  location: string;
  status: string;
  cars: string;
  photosCount: string;
}

export const DamageTable: React.FC = () => {


  const columns: ColumnsType<DataType> = [
    {
      title: 'Case Number',
      dataIndex: 'caseNumber',
      key: 'caseNumber'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time'
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        let color = 'default';
        if (text === 'Open') {
          color = '#87d068'
        } else if (text === 'Closed') {
          color = "#f50"
        }
        return <Tag
          style={{width: 100, fontWeight: 'bold', textAlign: 'center'}}
          color={color}
        >
          {text.toUpperCase()}
        </Tag>
      }
    }, {
      title: 'Cars',
      dataIndex: 'cars',
      key: 'cars'
    }, {
      title: 'Photos Count',
      dataIndex: 'photosCount',
      key: 'photosCount'
    },
    {
      title: '',
      dataIndex: 'navIcon',
      key: 'navIcon',
      render: () => {
        return (
          <RightCircleOutlined
            style={{ fontSize: '30px', color: '#09668c', cursor: 'not-allowed' }} />
        )
      }

    }
  ];

  const data: DataType[] = [
    {
      key: '1',
      caseNumber: '1238 - 23432',
      date: '24/09/2023',
      time: '19:34',
      location: 'Vagharshyan street',
      status: 'Open',
      cars: 'Toyota Camry',
      photosCount: '5'
    },
    {
      key: '2',
      caseNumber: '1238 - 23433',
      date: '24/09/2023',
      time: '19:38',
      location: 'Komitas street',
      status: 'Open',
      cars: 'BMW',
      photosCount: '6'
    },
    {
      key: '3',
      caseNumber: '1238 - 23434',
      date: '25/09/2023',
      time: '12:02',
      location: 'Shirvanzade street',
      status: 'Open',
      cars: 'Hyundayi',
      photosCount: '7'
    },
    {
      key: '4',
      caseNumber: '1238 - 23435',
      date: '25/09/2023',
      time: '14:45',
      location: 'Aharonyan street',
      status: 'Open',
      cars: 'Hyundayi',
      photosCount: '3'
    },
    {
      key: '5',
      caseNumber: '1238 - 23436',
      date: '26/09/2023',
      time: '15:42',
      location: 'Adonc street',
      status: 'Closed',
      cars: 'Nissan',
      photosCount: '3'
    },
    {
      key: '6',
      caseNumber: '1238 - 23437',
      date: '27/09/2023',
      time: '11:11',
      location: 'Garegin Njdeh',
      status: 'Open',
      cars: 'Lada Niva',
      photosCount: '11'
    },
    {
      key: '7',
      caseNumber: '1238 - 23438',
      date: '28/09/2023',
      time: '11:23',
      location: 'Vracakan Street',
      status: 'Closed',
      cars: 'Opel',
      photosCount: '11'
    },
    {
      key: '8',
      caseNumber: '1238 - 23439',
      date: '30/09/2023',
      time: '12:34',
      location: 'Mamikonyanc Street',
      status: 'Open',
      cars: 'Mercedes Benz',
      photosCount: '7'
    },
    {
      key: '9',
      caseNumber: '1238 - 23440',
      date: '30/09/2023',
      time: '12:34',
      location: 'Qeru Street',
      status: 'Open',
      cars: 'BMW',
      photosCount: '7'
    },
    {
      key: '10',
      caseNumber: '1238 - 23441',
      date: '30/09/2023',
      time: '12:34',
      location: 'Kievyan Street',
      status: 'Open',
      cars: 'Toyota',
      photosCount: '7'
    }
  ];
  return (
    <div className={'accidents-list'}>
      <PageTitle title={PageTitles.damage.list} />
      <div className={'custom-container'}>
        <Table pagination={false} dataSource={data} columns={columns} />
      </div>
    </div>
  )
}
