import React, { FC } from 'react';
import { Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons/lib';
import './styles.scss'

interface IPageTitle {
  title: string;
  onBack?: () => void;
}

export const PageTitle: FC<IPageTitle> = React.memo((props) => {
  const { title, onBack } = props;
  return (
    <Typography className={'page-title'}>
      {onBack && <ArrowLeftOutlined />}
      {title}
    </Typography>
  );
})