import React, { useCallback, useRef, MutableRefObject } from 'react';
import { Spin } from 'antd';
import { PageTitle } from '../pageTitle'
import { useAppSelector } from 'store/hooks';
import { useAppDispatch } from 'store/hooks';
import { PageTitles } from '../../constants';
import { InboxOutlined } from '@ant-design/icons';
import { selectLoadingState } from 'components/uploadImage/imageSlice';
import { uploadAndFetchImage } from 'components/uploadImage/imageSlice';
import { selectDisplayedImage } from 'components/uploadImage/imageSlice';
import 'components/uploadImage/style.scss'

export const UploadImage = () => {
  const dispatch = useAppDispatch();
  const inputRef: MutableRefObject<any> = useRef();
  const displayedImage = useAppSelector(selectDisplayedImage);
  const loading = useAppSelector(selectLoadingState);

  const onChange = useCallback((e: any) => {
    dispatch(uploadAndFetchImage(e.target.files[0]));
    inputRef.current.value = "";
  }, [inputRef, dispatch]);

  const onUploaderChange = useCallback(() => {
    inputRef.current.click()
  }, []);

  return (
    <Spin spinning={loading} tip='Loading' size="large" style={{ marginTop: '30px' }}>
      <PageTitle title={PageTitles.damage.upload} />
      <input ref={inputRef} type={'file'} style={{ display: 'none' }} onChange={onChange} />
      <div className={'uploader'} onClick={onUploaderChange}>
        <p className="upload-icon">
          <InboxOutlined />
        </p>
        <p className="upload-text">Click to upload image</p>
        <p className="upload-hint">
          You can upload only images, the maximum size is ~3 MB, formats (X, Y, Z)
        </p>
      </div>
      {displayedImage && (
          <div className='image-container'>
            <img className='image' alt={'img'} style={{ marginTop: '30px' }} src={displayedImage} />
          </div>
        )
      }
    </Spin>
  )
}