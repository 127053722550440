import { Layout } from 'antd';
import { Row } from 'antd';
import { Col } from 'antd';
import React from 'react';

const {Footer} = Layout

export const AppFooter = React.memo(() => {
  return (
    <Footer>
      <Row justify={'space-between'}>
        <Col>
          Copyright©2022-{new Date().getFullYear()} Damage Inspection.
        </Col>
        <Col>
          <b>Inspect, Detect, Protect: Trust Our Experience</b>
        </Col>
      </Row>
    </Footer>
  );
})